<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選查詢 
              i.fe-settings.ml-2
            a.collapse-turn.mr-2(@click="visible = !visible", href="javascript: void(0);")
              span(v-if="visible") 收起 
                i.fe-chevron-up
              span(v-else) 展開 
                i.fe-chevron-down
            b-button.ml-1.width-lg(variant="primary" @click="reloadList") 查詢
            b-button.ml-1.width-lg(variant="secondary" @click="resetSearch") 清除
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label ID
                    br
                    input.form-control(v-model="search.id", type="text", placeholder="請輸入ID")
                .col-lg-4
                  .form-group.mb-3
                    label 標題相關字
                    br
                    input.form-control(v-model="search.word", type="text", placeholder="請輸入標題相關字")
                .col-lg-4
                  .form-group.mb-3
                    label 議題分類
                    br
                    .row
                      .col-6
                        multiselect(v-model="search.mainType", :options="$root.newsTypeList", placeholder="選擇主分類", label="name", track-by="id", select-label="")
                      .col-6
                        multiselect(v-model="search.subType", :options="(search.mainType && search.mainType.sub_types) || []", placeholder="選擇次分類", label="name", track-by="id" select-label="")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label TAG
                    br
                    multiselect(v-model="search.tag", :options="tagsOptions", @search-change="searchTagList", placeholder="請選擇TAG", label="name", track-by="id", select-label="")
                .col-lg-4
                  .form-group.mb-3
                    label 投票類型
                    br
                    multiselect(v-model="search.electionType", :options="electionTypeOptions", placeholder="請選擇啟用狀態", label="name", track-by="id", select-label="")
                .col-lg-4
                  .form-group.mb-3
                    label 投票狀態
                    br
                    multiselect(v-model="search.status", :options="statusOptions", placeholder="請選擇投票狀態", label="name", track-by="id", select-label="")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label 創建人員
                    br
                    multiselect(v-model="search.editerUser", :options="editorOptions", @search-change="searchManagerList", placeholder="請選擇人員", label="name", track-by="id", select-label="")
                .col-lg-4
                  .form-group.mb-3
                    label
                      select(v-model="search.dateType", class="form-control label-select")
                        option(v-for="(type) in dateTypeOptions", :key="type.id", :value="type.id") {{ type.name }}
                    br
                    date-picker(
                      :value="daterange" 
                      @input="onDateSelect"
                      range
                      append-to-body
                      lang="en"
                      confirm
                      format="YYYY-MM-DD"
                      placeholder="請選擇日期區間"
                    )
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 投票列表
            b-button.width-lg(variant="outline-secondary", @click="reloadList")
              i.fe-refresh-ccw
              span.ml-2 重新整理  
            b-button.ml-2.width-lg(variant="primary" to="/management-setting")
              i.ri-eraser-fill
              span.ml-2 新增投票  
          //- Table
          .row
            .col-12
              b-table(
                ref="table"
                :items="searchVoteList",
                :fields="fields",
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered")

                template(v-slot:cell(create_date)="row")
                  span(v-if="!row.item.create_date") - 
                  span(v-else) {{ row.item.create_date }}

                template(v-slot:cell(main_type)="row")
                  span(v-if="!row.item.main_type.id") -
                  span(v-else) {{ row.item.main_type.name }}
                    span(v-if="row.item.sub_type.id") {{ ' / ' + row.item.sub_type.name }}

                template(v-slot:cell(tags)="row")
                  span(v-if="!row.item.tags.length") -
                  b-button.btn-soft-secondary.mr-1(
                    variant="secondary",
                    size="sm",
                    pill,
                    :key="tag.id"
                    v-for="tag in row.item.tags"
                  )
                    | {{ tag.name }}

                template(v-slot:cell(type)="row")
                  span(
                    v-for="( type, index ) in electionTypeOptions"
                    v-if="row.item.type === type.id"
                  ) {{ type.name }}

                template(v-slot:cell(on_website)="row")
                  b-button(v-if="row.item.on_website === 0" variant="secondary" size="sm") 未顯示
                  b-button(v-else-if="row.item.on_website === 1" variant="primary" size="sm") 顯示中

                template(v-slot:cell(status)="row")
                  b-button(v-if="row.item.status === 0" variant="secondary" size="sm") 未開始
                  b-button(v-else-if="row.item.status === 1" variant="primary" size="sm") 進行中
                  b-button(v-else variant="success" size="sm") 已結束

                template(v-slot:cell(join_count)="row")
                  span(v-if="row.item.join_count || row.item.join_count === 0") {{ row.item.join_count }} 
                  span(v-else) -

                template(v-slot:cell(start_date)="row")
                  span(v-if="!row.item.start_date") -
                  span(v-else) {{ row.item.start_date }}

                template(v-slot:cell(end_date)="row")
                  span(v-if="!row.item.end_date") - 
                  span(v-else) {{ row.item.end_date }}

                template(v-slot:cell(editer)="row")
                  span(v-if="!row.item.editer") -
                  span(v-else) {{ row.item.editer.name }}

                template(v-slot:cell(action)="row")
                  b-button.table-btn.mr-2(
                    v-if="row.item.type < 4 && (row.item.on_website === 1)"
                    @click="onPreview(row.item.id)"
                  )
                    | 檢視
                  b-button.table-btn.mr-2(
                    :to="`/management-setting/${row.item.id}`"
                  )
                    | 編輯
                  b-button.table-btn(
                    v-if="row.item.on_website === 1"
                    @click="offlineVoteSetting(row.item.id)"
                  )
                    | 下架
                  b-button.table-btn(
                    v-if="row.item.on_website === 0"
                    @click="removeVoteSetting(row.item.id)"
                  )
                    | 刪除
          .row.mb-md-2
            .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center
                  | Show
                  b-form-select.ml-2.mr-2(v-model="perPage", size="sm", :options="pageOptions")
                  | entries
            .col-6
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";


/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "投票編輯",
      items: [
        {
          text: "投票管理",
          href: "/management",
        },
        {
          text: "投票編輯",
          active: true,
        }
      ],
      search: {
        id: null,
        word: '',
        mainType: null,
        subType: null,
        tag: null,
        electionType: null,
        status: null,
        editerUser: null,
        dateType: 1,
        startDate: null,
        endDate: null,
        searchSource: 1
      },
      tagsOptions: [],
      editorOptions: [],
      electionTypeOptions: [
        { id: 0, name: '全部' },
        { id: 1, name: '選擇題' },
        { id: 2, name: '話題 PK' },
        { id: 3, name: '選前民調' },
        { id: 4, name: '話題人物' },
      ],
      statusOptions: [
        { id: 1, name: '未開始' },
        { id: 2, name: '進行中' },
        { id: 3, name: '已結束' }
      ],
      dateTypeOptions: [
        { id: 1, name: '創建日期' },
        { id: 2, name: '投票開始日' },
        { id: 3, name: '投票截止日' },
      ],
      daterange: '',
      visible: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'create_date',
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "create_date",
          label: "創建日期",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "main_type",
          label: "分類",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "title",
          label: "標題",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "tags",
          label: "TAGS",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "type",
          label: "投票類型",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "on_website",
          label: "前台狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "投票狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "join_count",
          label: "投票次數",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "start_date",
          label: "投票開始日",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "end_date",
          label: "投票截止日",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "editer",
          label: "創建人員",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "操作",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        },
      ]
    };
  },
  computed: {
  },
  mounted () {
    this.searchTagList(null)
    this.searchManagerList(null)
    this.reloadList()
  },
  methods: {
    reloadList () {
      this.$refs.table.refresh()
    },
    searchVoteList (_table, _callback) {
      const vm = this
      const searchParam = {
        ID: this.search.id,
        word: this.search.word,
        main_typeId: this.search.mainType?.id ?? null,
        sub_typeId: this.search.subType?.id ?? null,
        tag_id: this.search.tag?.id ?? null,
        election_type: this.search.electionType?.id ?? null,
        status: this.search.status?.id ?? null,
        editer_userId: this.search.editerUser?.id ?? null,
        date_type: this.search.dateType ?? null,
        start_date: this.search.startDate || null,
        end_date: this.search.endDate || null,
        search_source: this.search.searchSource ?? null,
        per_page: this.perPage || 10,
        page: this.currentPage || 1,
        sort: this.sortDesc ? 'desc' : 'asc',
        sort_by: this.sortBy || 'create_date',
      }
      this.$root.apis.getVoteList(searchParam,
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            _callback(response.polls)
          }
        },
        function (_error) {
          console.error(_error)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    resetSearch () {
      this.search = {
        id: null,
        word: '',
        mainType: null,
        subType: null,
        tag: null,
        electionType: null,
        status: null,
        editerUser: null,
        dateType: 1,
        startDate: null,
        endDate: null,
        searchSource: 1
      }
      this.daterange = ''
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.tagsOptions = _response.body.data.items
      })
    },
    // 搜尋人員列表
    searchManagerList (_keyword) {
      let vm = this

      this.$root.apis.getManagersList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.editorOptions.push(_item)
        })
      })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onDateSelect (_dateRange) {
      this.daterange = _dateRange
      if (_dateRange.length > 0) {
        this.search.startDate = this.$root.common.getNowTime('date', _dateRange[0])
        this.search.endDate = this.$root.common.getNowTime('date', _dateRange[1])
      }
    },
    offlineVoteSetting (_voteId) {
      let vm = this
      this.$root.common.confirmAction(
        "下架投票",
        `確定要下架這個投票 ID : ${_voteId}？` + "\r\n" + "請注意: 下架將會從前台隱藏該投票項目！",
        "下架", "再想一下",
        function () {
          vm.$root.apis.offlineVote(_voteId,
            function (_response) {
              vm.reloadList()
            }, function (_error) {
              console.error(_error)
            })
        })
    },
    removeVoteSetting (_voteId) {
      const vm = this
      this.$root.common.confirmAction("刪除投票", `確定要刪除投票 ID : ${_voteId}？`, "刪除", "再想一下",
        function (_result) {
          vm.$root.apis.removeVote(_voteId,
            function (_response) {
              vm.reloadList()
            },
            function (_error) {
              console.error(_error)
            })
        }
      )
    },
    onPreview (_voteId) {
      window.open(this.$root.site_uri + '/polls/' + _voteId)
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem 
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
</style>
